/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCpIMVP_WJF0TNi-2Mlf84Sp9wQbyvPE78",
  "appId": "1:950063020649:web:796688b6f53426690900f8",
  "authDomain": "schooldog-i-gainesville-ga.firebaseapp.com",
  "measurementId": "G-55HEBKBPRP",
  "messagingSenderId": "950063020649",
  "project": "schooldog-i-gainesville-ga",
  "projectId": "schooldog-i-gainesville-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-gainesville-ga.appspot.com"
}
